import {ListenerInstance} from "~/chat/ts/service/ListenerInstance";
import {ConfigStore} from "~/chat/ts/store/Config";
import {ClientStore} from "~/chat/ts/store/Client";
import Url from "~/ts/library/Url";
import MessageListener from "~/chat/ts/service/listen/MessageListener";
import DeleteMessageListener from "~/chat/ts/service/listen/DeleteMessageListener";
import OperatorTypingListener from "~/chat/ts/service/listen/OperatorTypingListener";
import GeoipListener from "~/chat/ts/service/listen/GeoipListener";
import CobrowseListener from "~/chat/ts/service/listen/CobrowseListener";
import AddToHistoryListener from "~/chat/ts/service/listen/AddToHistoryListener";
import OutgoingMessageListener from "~/chat/ts/service/listen/OutgoingMessageListener";
import OperatorsOnlineUpdatedListener from "~/chat/ts/service/listen/OperatorsOnlineUpdatedListener";
import SnippetListener from "~/chat/ts/service/listen/SnippetListener";
import SelectOperatorListener from "~/chat/ts/service/listen/SelectOperatorListener";
import SetBanListener from "~/chat/ts/service/listen/SetBanListener";
import SetAttachedOperatorsListener from "~/chat/ts/service/listen/SetAttachedOperatorsListener";
import RequestRateListener from "~/chat/ts/service/listen/RequestRateListener";
import ChangeOperatorListener from "~/chat/ts/service/listen/ChangeOperatorListener";
import RedirectListener from "~/chat/ts/service/listen/RedirectListener";
import CloseWidget from "~/chat/ts/service/listen/CloseWidget";
import OpenWidgetTabListener from "~/chat/ts/service/listen/OpenWidgetTabListener";
import Dictionary from "~/ts/library/Dictionary";
import ChatNodeRequest from "~/chat/ts/service/ChatNodeRequest";
import SetActionFilterValue from "~/chat/ts/service/listen/SetActionFilterValue";
import WebSocketChannel from "~/ts/library/listen/channels/WebSocketChannel";
import IsTargetChangedListener from "~/chat/ts/service/listen/IsTargetChangedListener";
import MessageRead from "~/chat/ts/service/listen/MessageRead";
import WidgetDataListener from "~/chat/ts/service/listen/WidgetDataListener";

export default class InitListener {
    private static get isTest() {
        return ConfigStore.setup.value.test;
    }

    public static async init() {

        let websocketUrl = InitListener.getWebsocketUrl();
        let longPollUrl = InitListener.getLongPollUrl();

        ListenerInstance
            .listenAction(new MessageListener())
            .listenAction(new AddToHistoryListener())
            .listenAction(new OutgoingMessageListener())
            .listenAction(new DeleteMessageListener())
            .listenAction(new OperatorTypingListener())
            .listenAction(new GeoipListener())
            .listenAction(new WidgetDataListener())
            .listenAction(new CobrowseListener())
            .listenAction(new OperatorsOnlineUpdatedListener())
            .listenAction(new SnippetListener())
            .listenAction(new SelectOperatorListener())
            .listenAction(new SetBanListener())
            .listenAction(new SetAttachedOperatorsListener())
            .listenAction(new RequestRateListener())
            .listenAction(new ChangeOperatorListener())
            .listenAction(new RedirectListener())
            .listenAction(new CloseWidget())
            .listenAction(new OpenWidgetTabListener())
            .listenAction(new SetActionFilterValue())
            .listenAction(new IsTargetChangedListener())
            .listenAction(new MessageRead());

        let listenUid = [ConfigStore.orgId.value, ConfigStore.siteId.value, ClientStore.id.value].join("/");

        /*await */
        let promise = ListenerInstance.start(listenUid, websocketUrl, longPollUrl, null, true);
        if (WebSocketChannel.isAvailable() && websocketUrl) {
            await promise;
        }
    }

    private static get credentialsQueryString() {
        return "?" + Url.toQueryString(ChatNodeRequest.getClientCredentials() as any as Dictionary<string>);
    }

    private static getWebsocketUrl(): string {
        if (!ConfigStore.setup.value.forceLongPolling) {
            return (this.isTest && !ConfigStore.isIe.value ? "wss://dev.apibcknd.com" : "wss://widget.site-chat.me") + "/comet/websocket" + this.credentialsQueryString;
        } else {
            return null;
        }
    }

    private static getLongPollUrl(): string {
        let host = this.isTest ? "https://dev.apibcknd.com" : ConfigStore.apiHost.value;
        return host + "/comet/longPolling" + this.credentialsQueryString;
    }
}